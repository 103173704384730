import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { faInfinity, faPlane, faUserFriends, faRoute, faTicket, faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AviTracerApi } from "../../avitracerApi";
import { useAppSelector } from "../../store/hooks";
import { selectedOrganization } from "../../store/organizationsSlice";
import { useState } from "react";

function AccountMainPage() {
  
  const selectedOrg = useAppSelector(selectedOrganization);

  return (
    <Container fluid className="vh-100 overflow-auto">
      <div className="fs-2 pt-5">My account</div>

      <h2 className="pt-5">Your Credits</h2>
      {selectedOrg?.billing.type === "invoice" ? (
        <>
          You are on a <strong>Pay as you go</strong> plan.
          <CreditsPricing />
        </>
      ) : (
        <>
          You have <strong>{selectedOrg!.billing.credits}</strong> credits.
          <CreditsPricing />
          <BuyCreditsFullComponent />
        </>
      )}
      <hr />
      <Button variant={"light"} href="/auth/logout" className="mb-5">
        Logout
      </Button>
    </Container>
  );
}

export default AccountMainPage;

function BuyCreditsFullComponent() {
  return (
    <Row className="">
      <Col xs={12} md={10} xl={4}>
        <BuyCreditsItem name="Basic" credits={45} price={24.99} productId="45_brief_credits" />
      </Col>
      <Col xs={12} md={10} xl={4}>
        <BuyCreditsItem name="Commercial" subtitle="Save 20%" credits={285} price={123.99} productId="285_brief_credits" />
      </Col>
    </Row>
  );
}

interface BuyCreditsItemProps {
  name: string
  subtitle?: string
  credits: number
  price: number
  productId: "45_brief_credits" | "285_brief_credits"
}

function BuyCreditsItem({name, subtitle, credits, price, productId}: BuyCreditsItemProps) {

  const [openingUrl, setOpeningUrl] = useState(false)

  const items = [
    { 
      title: credits + " non expiring credits",
      icon: faInfinity },
    {
      title: "Unlimited aircraft",
      icon: faPlane,
    },
    {
      title: "Unlimited crew",
      icon: faUserFriends,
    },
    {
      title: "Unlimited passengers",
      icon: faUserFriends,
    },
    {
      title: "Unlimited routes",
      icon: faRoute,
    },
    {
      title: "Unlimited boarding passes",
      icon: faTicket,
    }
  ];

  const buyPressed = async () => {
    setOpeningUrl(true)
    
    const {url} = await AviTracerApi.requestCreditsPurchaseLink(productId)
    window.open(url)
    
    setOpeningUrl(false)
  }

  return (
    <div className="my-4 shadow rounded">
      <div className="p-3">
        <p className="fs-3 fw-medium">{name}</p>
        <Nav>
          <Nav.Item style={{borderBottom:"2px solid black"}}> 
          {credits} Credits
          </Nav.Item>
        </Nav>
        <p className="text-decoration-underline"></p>
        <div className="fs-1">€{price}</div>

        <span className="text-muted">
          <em>{subtitle ? <>{subtitle}</> : <br />}</em>
        </span>

        <div className="pt-4">
          {items.map((item) => (
            <p key={item.title} className="mb-1">
              <span >
                <FontAwesomeIcon icon={item.icon} size={"1x"} style={{width:" 20px"}}/>
              </span>
              <span className="font-weight-light ps-2">
                {item.title}
                <br />
              </span>
            </p>
          ))}
        </div>

        <div className="d-flex align-items-center pt-5">
          <div className="d-grid w-100">
            <Button size="lg" variant="dark" className="fw-bold" onClick={buyPressed} disabled={openingUrl}>
              {openingUrl ? "Loading..." : <>Buy {credits} credits</>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CreditsPricing() {
  return (
    <div className="pt-1">
      <small>
        Credits can be used for:
        <ul>
          <li> Flight Plan Filing: 1 credit/file</li>
          <li> Briefing Pack Generation: 2 credits/flight</li>
        </ul>
      </small>
    </div>
  );
}